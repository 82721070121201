<template>
    <div>
        <Header headerType="advance_result"/>  

        <div :class="'container-fluid' + ($route.query.zoom ? ' fullscreen' : '') + (isUserFrench ? ' page-french' : '')">
            <div class="row">
                <div class="main-box-fixed">                        
                    <div class="box-fixed" >
                        <div class="title">
                            <div v-if="isFilter" class="count_filter">
                                {{ count_filter + "/" + count }}
                            </div>
                            <h1 v-else>
                                {{ count }}
                            </h1>
                            <h2>{{$t('Result')}}</h2>
                        </div>
                        <div class="filters-plus" @click="handleClickShowFilter()">
                            <img :src="require(`@/assets/dist/2019/images/icon/btn-filtre-off${isUserFrench ? '-fr' : ''}.svg`)" alt="" >
                            <p>{{$t('Filters')}}</p>
                        </div>
                    </div>
                    <FilterTransparence @onRequest="request_data"  @isFilter="value => isFilter = value"/>
                    <div class="advance box-fix-left" >
                        <ul>
                            <label class="flag-active">  
                                                             
                                <li class="active pointerNone" >
                                    <img :src="require('@/assets/images/flag/new/fr.svg')" width="35" height="35" style="margin-bottom: 5px;" />
                                    <p> 
                                        <span style="margin-right: 0;"></span>
                                        {{$t('TC')}}
                                    </p>
                                    <p class="short_name">(FRA)</p> 
                                </li>                                
                            </label>

                             

                        </ul>
                    </div>
                </div>

                <div class="main-body">
                    <div class="body-fix">
                        <div class="body-custom">
                            <div class="box-btn-menu">                                
                                <a href="javascript:void(0)" class="btn btn-border-orange btn-muted pointerNone">{{$t('Overview')}}</a>
                                <a href="javascript:void(0)" @click="selectTab('expert')" :class="'btn ' + (!$route.query.tab || $route.query.tab == 'expert' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Expert')}}</a>
                                <a v-if="user && user.access_clinical" href="javascript:void(0)" @click="selectTab('essais_clinique')" :class="'btn ' + ($route.query.tab && $route.query.tab == 'essais_clinique' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Clinical trials')}}</a>
                                <a href="javascript:void(0)" @click="selectTab('prix')" :class="'btn ' + ($route.query.tab && $route.query.tab == 'prix' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Prix')}}</a>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="body-fix740">
                        <div class="body-custom">                             
                            <div class="box-overview">
                                <div class="box-detail">
                                    <div class="row">
                                        <div class="col-12 col-lg-12 col-md-12">
                                            <div class="wrapper-box-hta">
                                                <h1>{{ $t("transparence.ASMR obtained")}}</h1>
                                            </div>
                                            <div class="color-grey-body FX-Height">
                                                
                                                <div id="chart_asmr_obtained" class="div_highchart" style="width: 100%; height: 100%; position: relative; display: flex; flex-wrap: wrap;align-content:center;justify-content: center;">
                                                    <div v-if="!data_chart.length" style="text-align:center">{{$t('No Data')}}</div>
                                                </div>
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>    

                            </div>

                        </div>

                    </div> -->
                      

                    <div v-if="(!$route.query.tab || $route.query.tab == 'expert')">
                    <div class="body-w100" v-if="data.data && data.data.length > 0">
                        <div class="box-detail">
                            <!-- <div class="wrapper-box-hta">
                                <h1 class="fa-pull-left mr-4 mt-1">Reimbursement Status</h1>
                                <div class="clearfix"></div>
                            </div>  -->

                            <ConfigColumnAdvance :type="type" :column="column" :reset_column="reset_column" />
                            <ModalSaveKeyword :type="type" />
                            <IconTopTable @onRequest="request_data" :page="page" @onSelectExport="selectExport" @onClearExport="clearExport"/>
                            <div class="content-table-results mt-2">
                                <table class="table table-fixed table-stripes" :id="actionName" >
                                    <ColumnColGroup page="transparence" :column="orderColumn" :default_column="default_column" />
                                    <ColumnThead page="transparence" :column="orderColumn" :default_column="default_column" />  
                                    <tbody> 
                                        <template v-for="(item, key) in data.data" :key="key">
                                        <tr v-for="(sub_item, sub_key) in max_row(item)" :key="key+'_'+sub_key" :class="odd_even_border(key, sub_key)">                      
                                            <td>
                                                <template v-if="sub_key===0">
                                                    <label class="table-check">
                                                        <input type="checkbox"  v-model="select_export" :value="item.drug_id">
                                                        <span class="checkbox-table"></span>
                                                    </label>
                                                </template>
                                            </td>
                                            <td>
                                                <template v-if="sub_key===0">
                                                <span v-html="icon_link(item.drug_id,'trs')"></span>
                                                <div v-if="isAdmin">
                                                    <a :href="modifyPage('transparences', item.drug_id)" target="_blank" rel="noopener"> 
                                                        <em class="icon-edit"></em>
                                                    </a>
                                                </div>
                                                </template>
                                            </td>
                                            <template v-for="(column, keycolumn) in orderColumn" :key="key+'_'+sub_key+'_'+keycolumn" >
                                                <td v-if="column.Status && column.code == 'general_001'" :code="column.code">
                                                    <span v-if="sub_key===0"> {{item.drug_id}} </span>                                                    
                                                </td>                                                
                                                <td v-else-if="column.Status && column.code == 'general_003'" :code="column.code">
                                                   <span v-if="sub_key===0" v-html="column_status(item)"></span>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_004'" :code="column.code">
                                                    <template v-if="sub_key===0">{{check_empty(item.dci)}}</template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_005'" :code="column.code">
                                                    <template v-if="sub_key===0">{{check_empty(item.name)}}</template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_006'" :code="column.code">
                                                    <template v-if="sub_key===0"><span v-html="check_empty(array(item.atc))"></span></template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_007'" :code="column.code">
                                                    <template v-if="sub_key===0">{{check_empty(item.firm)}}</template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_002'" :code="column.code">
                                                    <span  v-if="sub_key===0" v-html="check_empty(array(item.indexation))"> </span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'general_008'" :code="column.code">
                                                    <template v-if="sub_key===0">{{ check_empty(DDMMMYY(item.transparence.trs_date_type_amm))}}</template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_009'" :code="column.code">
                                                    <template v-if="sub_key===0">{{ check_empty(DDMMMYY(item.transparence.trs_ordre_du_jour_date))}}</template>
                                                </td>  
                                                <td v-else-if="column.Status && column.code == 'general_010'" :code="column.code">
                                                    <template v-if="sub_key===0">{{ check_empty(DDMMMYY(item.transparence.trs_date))}}</template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_011'" :code="column.code">
                                                    <template v-if="sub_key===0">{{ check_empty(DDMMMYY(item.transparence.trs_date_depot_ct))}}</template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_012'" :code="column.code">
                                                    <template v-if="sub_key===0"> 
                                                        <div v-if="item.transparence.trs_type_demandes" v-html="$t('transparence.trs_type_demandes.'+item.transparence.trs_type_demandes)"></div>
                                                        <div v-if="item.transparence.raison_reevaluation" v-html="$t('transparence.raison_reevaluation.'+item.transparence.raison_reevaluation)"></div>
                                                        <span v-if="!item.transparence.raison_reevaluation && !item.transparence.trs_type_demandes"> - </span>
                                                    </template> 
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_013'" :code="column.code">
                                                    <template v-if="sub_key===0">{{check_empty_with_locale(item.transparence.trs_type_procedure)}}</template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_014'" :code="column.code">
                                                    <template v-if="sub_key===0">{{check_empty(item.transparence.trs_motif)}}</template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_015'" :code="column.code">
                                                    <template v-if="sub_key===0">{{item.transparence.trs_resrtriction === 1 ? $t('Yes') : "-"}} </template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_016'" :code="column.code">
                                                    <template v-if="sub_key===0"> 
                                                        <template v-if="$i18n.locale !== 'fr'"> 
                                                            <div v-html="check_empty(array(item.classification_en))"></div>
                                                        </template>
                                                        <template v-else>  
                                                            <div v-html="check_empty(array(item.classification_fr))"></div>
                                                        </template> 
                                                    </template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_017'" :code="column.code">
                                                    <template v-if="sub_key===0">
                                                        <template v-if="$i18n.locale !== 'fr'"> 
                                                            <Readmore :longText="item.transparence.trs_indication_en"/>
                                                        </template>
                                                        <template v-else>  
                                                            <Readmore :longText="item.transparence.trs_indication"/>
                                                        </template> 
                                                    </template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_018'" :code="column.code">
                                                    <template v-if="sub_key===0">{{item.transparence.trs_mk_exception === 1 ? $t('Yes') : "-"}}</template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_019'" :code="column.code">
                                                    <template v-if="sub_key===0">{{check_empty_with_locale(item.mk_orphelin)}}</template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_020'" :code="column.code">
                                                    <template v-if="sub_key===0">
                                                        <template v-if="$i18n.locale !== 'fr'"> 
                                                            <Readmore :longText="item.transparence.trs_cpd_en"/>
                                                        </template>
                                                        <template v-else>  
                                                            <Readmore :longText="item.transparence.trs_cpd"/>
                                                        </template> 
                                                    </template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_021'" :code="column.code">
                                                    <template v-if="sub_key===0">{{check_empty_with_locale(item.transparence.trs_type_amm, 'advance.TransparenceTC.type_amm.')}}</template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_022'" :code="column.code">
                                                    <template v-if="sub_key===0"><span v-html="check_empty(treatment_line(item.treatment_line))"></span></template>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'general_023'" :code="column.code">
                                                    <template v-if="sub_key===0">{{check_empty(item.transparence.trs_ref_avis_ct)}}</template>
                                                </td>  
                                                <td v-else-if="column.Status && column.code == 'general_024'" :code="column.code">
                                                    <template v-if="sub_key===0"> {{ item.transparence.transparence_contribution_patient.length > 0 ? $t('Yes') : "-" }}</template>
                                                </td>

                                                
                                                <td v-else-if="column.Status && column.code == 'general_029'" :code="column.code" class="middle">
                                                    <span v-html="rembursement_status_all_type(item)" v-if="sub_key===0"></span>
                                                </td>


                                                <!-- temp_smr -->
                                                <td v-else-if="column.Status && column.code == 'adv_tc_001'" :code="column.code">
                                                        {{ temp_smr[item.drug_id] && temp_smr[item.drug_id][sub_key] ? temp_smr[item.drug_id][sub_key]['smr_id'] : "-"}}
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_002'" :code="column.code">
                                                    <template v-if="temp_smr[item.drug_id] && temp_smr[item.drug_id][sub_key]">
                                                        <template v-if="$i18n.locale !== 'fr'"> 
                                                            <Readmore :longText="temp_smr[item.drug_id][sub_key]['smr_title_en']" textAlign="center"/>
                                                        </template>
                                                        <template v-else>  
                                                            <Readmore :longText="temp_smr[item.drug_id][sub_key]['smr_title']" textAlign="center"/>
                                                        </template> 
                                                    </template> 
                                                    <span v-else> - </span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_003'" :code="column.code">
                                                    <template v-if="temp_smr[item.drug_id] && temp_smr[item.drug_id][sub_key]">
                                                        <div v-html="smr_type(temp_smr[item.drug_id][sub_key]['smr_type'])" ></div>    
                                                        <span v-if="temp_smr[item.drug_id][sub_key]['smr_remboursement_non_demand'] && temp_smr[item.drug_id][sub_key]['smr_type']!='Remboursement non sollicité par la firme'">
                                                            {{$t('transparence.smr_remboursement_non_demand')}}
                                                        </span>                                                        
                                                    </template> 
                                                    <span v-else> - </span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_004'" :code="column.code">
                                                    <template v-if="temp_smr[item.drug_id] && temp_smr[item.drug_id][sub_key]">
                                                        <template v-if="$i18n.locale !== 'fr'"> 
                                                            <Readmore :longText="temp_smr[item.drug_id][sub_key]['smr_text_en']"/>
                                                        </template>
                                                        <template v-else>  
                                                            <Readmore :longText="temp_smr[item.drug_id][sub_key]['smr_text']"/> 
                                                        </template> 
                                                    </template> 
                                                    <span v-else> - </span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_028'" :code="column.code">
                                                    <template v-if="temp_smr[item.drug_id] && temp_smr[item.drug_id][sub_key]">
                                                        {{temp_smr[item.drug_id][sub_key]['conditionnel'] == 1 ? $t("Yes") : $t("No")}}
                                                    </template>
                                                    <template v-else>-</template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_005'" :code="column.code">
                                                        {{ temp_smr[item.drug_id] && temp_smr[item.drug_id][sub_key] ? temp_smr[item.drug_id][sub_key]['asmr_id'] : "-"}}
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_006'" :code="column.code">
                                                    <template v-if="temp_smr[item.drug_id] && temp_smr[item.drug_id][sub_key]">
                                                        <template v-if="$i18n.locale !== 'fr'"> 
                                                            <Readmore :longText="temp_smr[item.drug_id][sub_key]['asmr_title_en']" textAlign="center"/>
                                                        </template>
                                                        <template v-else>  
                                                            <Readmore :longText="temp_smr[item.drug_id][sub_key]['asmr_title']" textAlign="center"/>
                                                        </template> 
                                                    </template>
                                                    <span v-else> - </span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_007'" :code="column.code">
                                                        {{ temp_smr[item.drug_id] && temp_smr[item.drug_id][sub_key] ? check_empty_with_locale(temp_smr[item.drug_id][sub_key]['asmr_obtenue'],'transparence.asmr.') : '-'}}
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_008'" :code="column.code">
                                                        {{ temp_smr[item.drug_id] && temp_smr[item.drug_id][sub_key] ? check_empty(temp_smr[item.drug_id][sub_key]['asmr_demande']) : "-"}}
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_009'" :code="column.code">
                                                    <template v-if="temp_smr[item.drug_id] && temp_smr[item.drug_id][sub_key]">
                                                        <template v-if="$i18n.locale !== 'fr'"> 
                                                            <Readmore :longText="temp_smr[item.drug_id][sub_key]['asmr_text_en']"/>
                                                        </template>
                                                        <template v-else>  
                                                            <Readmore :longText="temp_smr[item.drug_id][sub_key]['asmr_text']"/> 
                                                        </template> 
                                                    </template> 
                                                    <span v-else> - </span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_010'" :code="column.code">
                                                    <template v-if="temp_smr[item.drug_id] && temp_smr[item.drug_id][sub_key]">
                                                        <span v-html="check_empty(json_fields(temp_smr[item.drug_id][sub_key]['isp_json'], 'ISP_libelle'))"></span>    
                                                    </template>
                                                    <span v-else> - </span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_011'" :code="column.code">
                                                    <template v-if="temp_smr[item.drug_id] && temp_smr[item.drug_id][sub_key]">
                                                        <template v-if="$i18n.locale !== 'fr'"> 
                                                            <Readmore :longText="json_fields(temp_smr[item.drug_id][sub_key]['isp_json'], 'ISP_text_en')"/>
                                                        </template>
                                                        <template v-else>  
                                                            <Readmore :longText="json_fields(temp_smr[item.drug_id][sub_key]['isp_json'], 'ISP_text')"/> 
                                                        </template>
                                                    </template>
                                                    <span v-else> - </span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_012'" :code="column.code">
                                                    <template v-if="sub_key===0">{{item.transparence.trs_epi === 1 ? $t('Yes') : "-"}} </template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_013'" :code="column.code">
                                                    <template v-if="sub_key===0">{{item.transparence.trs_epi_dep === 1 ? $t('Yes') : "-"}} </template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_014'" :code="column.code">
                                                    <template v-if="sub_key===0">
                                                        <template v-if="$i18n.locale !== 'fr'"> 
                                                            <Readmore :longText="item.transparence.trs_population_cible_en"/> 
                                                        </template>
                                                        <template v-else>  
                                                            <Readmore :longText="item.transparence.trs_population_cible"/> 
                                                        </template> 
                                                    </template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_015'" :code="column.code">
                                                    <template v-if="temp_smr[item.drug_id] && temp_smr[item.drug_id][sub_key]">
                                                        <template v-if="$i18n.locale !== 'fr'"> 
                                                            <span v-html="check_empty(json_fields(temp_smr[item.drug_id][sub_key]['nombre_de_patient_json'], 'NDP_text_en'))"></span> 
                                                        </template>
                                                        <template v-else>  
                                                            <span v-html="check_empty(json_fields(temp_smr[item.drug_id][sub_key]['nombre_de_patient_json'], 'NDP_text'))"></span> 
                                                        </template>    
                                                    </template> 
                                                    <span v-else> - </span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_016'" :code="column.code">
                                                    <template v-if="sub_key===0">{{item.transparence.amm_ct ? item.transparence.amm_ct + " " + $t("days") : "-" }}</template>                                                     
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_017'" :code="column.code">
                                                    <span v-if="sub_key===0" :class="color_type_limitation(item.prioritis_hta_index)">{{check_empty(item.prioritis_hta_index)}}</span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'adv_tc_018'" :code="column.code">
                                                    <template v-if="sub_key===0">
                                                        <template v-if="$i18n.locale !== 'fr'"> 
                                                            <Readmore :longText="item.transparence.rationale_commentary_en"/> 
                                                        </template>
                                                        <template v-else>  
                                                            <Readmore :longText="item.transparence.rationale_commentary"/> 
                                                        </template>    
                                                    </template>  
                                                </td>                                                
                                                <td v-else-if="column.Status && column.code == 'adv_tc_019'" :code="column.code">
                                                    <template v-if="temp_smr[item.drug_id] && temp_smr[item.drug_id][sub_key]">
                                                        <span v-html="check_empty(json_fields(temp_smr[item.drug_id][sub_key]['besoin_medical_json'], 'besoin_medical_select'))"></span>
                                                    </template>
                                                    <span v-else> - </span>
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'adv_tc_020'" :code="column.code">
                                                    <template v-if="sub_key===0">{{check_empty_with_locale(item.transparence.trs_avis_ct)}}</template>
                                                </td> 

                                                <template v-else-if="column.Status && column.code == 'adv_tc_021'">                                   
                                                    <td  :key="column.code+'_022'" code="adv_tc_022" class="middle" >                                    
                                                        <span @click="hta_status_modal(item)" data-bs-toggle="modal" data-bs-target="#modal-hta-status" v-html="hta_process_status_2(item)" v-if="sub_key===0"></span>
                                                    </td>     
                                                    <td  :key="column.code+'_023'" code="adv_tc_023" class="middle" >                                      
                                                        <span @click="hta_status_modal(item)" data-bs-toggle="modal" data-bs-target="#modal-hta-status" v-html="rembursement_status_2(item)" v-if="sub_key===0"></span>                        
                                                    </td>     
                                                    <td  :key="column.code+'_024'" code="adv_tc_024" class="middle">    
                                                        <span v-html="datediff(item.ma_date, item.rembursement_process_date)" v-if="sub_key===0"></span>
                                                    </td>    
                                                </template> 

                                                <td v-else-if="column.Status && column.code == 'adv_tc_025'" :code="column.code">
                                                    <span v-html="ema_id(item.links)" v-if="sub_key===0"></span>
                                                </td>  
                                                <td v-else-if="column.Status && column.code == 'adv_tc_026'" :code="column.code">
                                                   <span v-if="sub_key===0"> {{check_empty(item.transparence.trs_id)}}</span>
                                                </td>  
                                                <td v-else-if="column.Status && column.code == 'adv_tc_027'" :key="column.code" :code="column.code">
                                                    <template v-if="sub_key===0">
                                                        <template v-if="$i18n.locale !== 'fr'"> 
                                                            <Readmore :longText="item.transparence.data_package_en"></Readmore>
                                                        </template>
                                                        <template v-else>  
                                                            <Readmore :longText="item.transparence.data_package_fr"></Readmore>
                                                        </template> 
                                                    </template>
                                                </td> 

                                                <td v-else-if="column.Status && column.code == 'comments_001'" :code="column.code">
                                                    <template v-if="sub_key===0">{{item.transparence.trs_modify_smr_asmr === 1 ? $t('Yes') : "-"}}</template>                                                    
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'comments_002'" :code="column.code">
                                                    <template v-if="sub_key===0">
                                                        <template v-if="$i18n.locale !== 'fr'"> 
                                                            <Readmore :longText="item.transparence.trs_commentaires_en"/> 
                                                        </template>
                                                        <template v-else>  
                                                            <Readmore :longText="item.transparence.trs_commentaires"/> 
                                                        </template>    
                                                    </template>  
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'indirect_comparison_001'" :code="column.code">
                                                    <template v-if="sub_key===0">{{ check_empty_with_locale(item.transparence.indirect_comparison_data)}}</template>                                                    
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'indirect_comparison_002'" :code="column.code">
                                                    <template v-if="sub_key===0">{{ check_empty_with_locale(item.transparence.acepted_ic)}}</template>                                                    
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'indirect_comparison_003'" :code="column.code">
                                                    <template v-if="sub_key===0"> 
                                                        <Readmore v-if="$i18n.locale === 'fr'" :longText="item.transparence.population_ic"/>  
                                                        <Readmore v-else :longText="item.transparence.population_ic_en"/>  
                                                    </template>                                                    
                                                </td>

                                                <td v-else-if="column.Status && column.code == 'quality_of_life_001'" :code="column.code">
                                                    <template v-if="sub_key===0">{{ check_empty_with_locale(item.transparence.quality_of_life_data)}}</template>                                                    
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'quality_of_life_002'" :code="column.code">
                                                    <template v-if="sub_key===0"> 
                                                        <Readmore v-if="$i18n.locale === 'fr'" :longText="item.transparence.type_of_qol_data_presented"/> 
                                                        <Readmore v-else :longText="item.transparence.type_of_qol_data_presented_en"/> 
                                                     </template>                                                    
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'quality_of_life_004'" :code="column.code">
                                                    <template v-if="sub_key===0">{{ check_empty_with_locale(item.transparence.accepted_qol)}}</template>                                                    
                                                </td>
                                                
                                                <td v-else-if="column.Status && column.code == 'phase_contradictoire_001'" :code="column.code">
                                                    <template v-if="sub_key===0"> {{ check_empty(DDMMMYY(item.transparence.trs_audition_date)) }} </template> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'phase_contradictoire_002'" :code="column.code">
                                                    {{ item.transparence.audition && item.transparence.audition[sub_key] ? check_empty(item.transparence.audition[sub_key]['audition_a_cocher']) : "-"}}
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'phase_contradictoire_003'" :code="column.code">
                                                    <template v-if="item.transparence.audition && item.transparence.audition[sub_key]">
                                                        <span v-html="objet_audition(item.transparence.audition[sub_key]['objet_audition'])"></span>                                 
                                                    </template>                                   
                                                    <span v-else> - </span>        
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'phase_contradictoire_004'" :code="column.code">
                                                    {{ item.transparence.audition && item.transparence.audition[sub_key] ? check_empty(item.transparence.audition[sub_key]['modification_audition']) : "-"}}
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'phase_contradictoire_005'" :code="column.code">
                                                    <template v-if="item.transparence.audition && item.transparence.audition[sub_key]">
                                                        <template v-if="$i18n.locale !== 'fr'"> 
                                                            <Readmore :longText="item.transparence.audition[sub_key]['audition_text_en']"/>
                                                        </template>
                                                        <template v-else>  
                                                            <Readmore :longText="item.transparence.audition[sub_key]['audition_text']"/> 
                                                        </template> 
                                                    </template>  
                                                    <span v-else> - </span> 
                                                </td>
                                                 <!-- temp_smr -->
                                                <td v-else-if="column.Status && column.code == 'prise_en_charge_001'" :code="column.code">
                                                    <template v-if="indication_jo_info && indication_jo_info[item.drug_id] && indication_jo_info[item.drug_id][sub_key]">
                                                        <span v-html="IndicationJoInfo(indication_jo_info[item.drug_id][sub_key], 'ss', item.transparence.trs_date)"></span>    
                                                    </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'prise_en_charge_002'" :code="column.code">
                                                    <template v-if="indication_jo_info && indication_jo_info[item.drug_id] && indication_jo_info[item.drug_id][sub_key]">
                                                        <span v-html="IndicationJoInfo(indication_jo_info[item.drug_id][sub_key], 'coll', item.transparence.trs_date)"></span>    
                                                    </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'prise_en_charge_003'" :code="column.code">
                                                    <template v-if="indication_jo_info && indication_jo_info[item.drug_id] && indication_jo_info[item.drug_id][sub_key]">
                                                        <span v-html="IndicationJoInfo(indication_jo_info[item.drug_id][sub_key], 't2a', item.transparence.trs_date)"></span>    
                                                    </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'prise_en_charge_004'" :code="column.code">
                                                    <template v-if="indication_jo_info && indication_jo_info[item.drug_id] && indication_jo_info[item.drug_id][sub_key]">
                                                        <span v-html="IndicationJoInfo(indication_jo_info[item.drug_id][sub_key], 'retro', item.transparence.trs_date)"></span>    
                                                    </template>
                                                    <span v-else> - </span> 
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'prise_en_charge_005'" :code="column.code" class="middle">
                                                    <template v-if="indication_jo_info && indication_jo_info[item.drug_id] && indication_jo_info[item.drug_id][sub_key]">
                                                        <span v-html="IndicationJoInfo(indication_jo_info[item.drug_id][sub_key], 'prise_en_charge_precoce', item.transparence.trs_date)"></span>    
                                                    </template>
                                                    <span v-else> - </span> 
                                                </td>  
                                                <td v-else-if="column.Status && column.code == 'internal_001'" :code="column.code">
                                                    <template v-if="temp_smr[item.drug_id] && temp_smr[item.drug_id][sub_key]">
                                                        <span v-html="check_empty(json_fields(temp_smr[item.drug_id][sub_key]['nombre_de_patient_json'], 'NDP_number_population'))"></span>    
                                                    </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                
                                                <!-- ctgov_study -->
                                                <td v-else-if="column.Status && column.code == 'clinical_001'" :code="column.code">
                                                    <template v-if="temp_ctgov[item.drug_id] && temp_ctgov[item.drug_id][sub_key]">
                                                        <span v-if="sub_key===0" @click="nct_id_popup = temp_ctgov[item.drug_id][sub_key]['nct_id']" data-bs-toggle="modal" data-bs-target="#modal-essais-clinique" style="cursor:pointer;">
                                                            {{ check_empty(temp_ctgov[item.drug_id][sub_key]['nct_id']) }}
                                                        </span>
                                                    </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'clinical_002'" :code="column.code">
                                                    <template v-if="temp_ctgov[item.drug_id] && temp_ctgov[item.drug_id][sub_key]">
                                                        {{ check_empty(temp_ctgov[item.drug_id][sub_key]['overall_status']) }} 
                                                    </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'clinical_003'" :code="column.code">
                                                    <template v-if="temp_ctgov[item.drug_id] && temp_ctgov[item.drug_id][sub_key]">
                                                        <Readmore :longText="temp_ctgov[item.drug_id][sub_key]['brief_title']"/>
                                                    </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'clinical_004'" :code="column.code">
                                                    <template v-if="temp_ctgov[item.drug_id] && temp_ctgov[item.drug_id][sub_key]">
                                                        {{ check_empty(temp_ctgov[item.drug_id][sub_key]['acronym'])}} 
                                                    </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'clinical_005'" :code="column.code">
                                                    <template v-if="temp_ctgov[item.drug_id] && temp_ctgov[item.drug_id][sub_key]">
                                                        {{ check_empty(DDMMMYY(temp_ctgov[item.drug_id][sub_key]['start_date']))}} 
                                                    </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'clinical_006'" :code="column.code">
                                                    <template v-if="temp_ctgov[item.drug_id] && temp_ctgov[item.drug_id][sub_key]">
                                                        {{ check_empty(DDMMMYY(temp_ctgov[item.drug_id][sub_key]['completion_date']))}} 
                                                    </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'clinical_007'" :code="column.code">
                                                    <template v-if="temp_ctgov[item.drug_id] && temp_ctgov[item.drug_id][sub_key]">
                                                        {{ check_empty(temp_ctgov[item.drug_id][sub_key]['phase'])}} 
                                                    </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'clinical_008'" :code="column.code">
                                                    <template v-if="temp_ctgov[item.drug_id] && temp_ctgov[item.drug_id][sub_key]">
                                                        {{ check_empty(join_text(temp_ctgov[item.drug_id][sub_key]['intervention'], "name")) }} 
                                                    </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'clinical_009'" :code="column.code">
                                                    <template v-if="temp_ctgov[item.drug_id] && temp_ctgov[item.drug_id][sub_key]">
                                                        <Readmore :longText="join_text(temp_ctgov[item.drug_id][sub_key]['condition'], 'name')" /> 
                                                    </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'clinical_010'" :code="column.code">
                                                    <template v-if="temp_ctgov[item.drug_id] && temp_ctgov[item.drug_id][sub_key]">
                                                        <Readmore :longText="display_design_outcome(temp_ctgov[item.drug_id][sub_key]['design_outcome'], 'primary')" />
                                                    </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'clinical_011'" :code="column.code">
                                                    <template v-if="temp_ctgov[item.drug_id] && temp_ctgov[item.drug_id][sub_key]">
                                                        <Readmore :longText="display_design_outcome(temp_ctgov[item.drug_id][sub_key]['design_outcome'], 'secondary')" />
                                                    </template>
                                                    <span v-else> - </span> 
                                                </td>

                                                <td v-else-if="column.Status && column.code == 'atu_005'" :code="column.code">
                                                    <template v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]"> {{ check_empty_with_locale(temp_atu_france[item.drug_id][sub_key].motif, 'atu_motif.') }} </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'atu_001'" :code="column.code">                                                    
                                                    <template v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]"> {{ check_empty_with_locale(temp_atu_france[item.drug_id][sub_key].avis) }} </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'atu_002'" :code="column.code">
                                                    <template v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]"> {{ check_empty(DDMMMYY(temp_atu_france[item.drug_id][sub_key].date_start)) }} </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'atu_003'" :code="column.code">
                                                    <template v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]"> {{ check_empty(DDMMMYY(temp_atu_france[item.drug_id][sub_key].date_atu_start))}} </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'atu_004'" :code="column.code">
                                                    <template v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]"> {{ check_empty(DDMMMYY(temp_atu_france[item.drug_id][sub_key].date_end)) }} </template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'atu_007'" :code="column.code">
                                                    <template v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]">{{ check_empty(DDMMMYY(temp_atu_france[item.drug_id][sub_key].date_validation_admininstrative)) }}</template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'atu_008'" :code="column.code">
                                                    <template v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]">{{ check_empty(DDMMMYY(temp_atu_france[item.drug_id][sub_key].date_examen_adoption)) }}</template>
                                                    <span v-else> - </span> 
                                                </td> 
                                                <td v-else-if="column.Status && column.code == 'atu_009'" :code="column.code">
                                                   <template v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]"> {{ check_empty(DDMMMYY(temp_atu_france[item.drug_id][sub_key].date_depot_ct)) }}</template>
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'atu_006'" :code="column.code">
                                                    <Readmore v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]" :longText="$i18n.locale === 'fr' ? temp_atu_france[item.drug_id][sub_key].indication_atu_fr : temp_atu_france[item.drug_id][sub_key].indication_atu_en" />
                                                    <span v-else> - </span> 
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'atu_010'" :code="column.code">
                                                    <template v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]">{{ check_empty_with_locale(temp_atu_france[item.drug_id][sub_key].besoin_therapeutique) }} </template>
                                                    <span v-else> - </span> 
                                                </td> 
                                                
                                                <td v-else-if="column.Status && column.code == 'aap_has_006'" :code="column.code">
                                                    <template v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]">{{ check_empty_with_locale(temp_atu_france[item.drug_id][sub_key].type_aap) }} </template>
                                                    <span v-else> - </span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'aap_has_003'" :code="column.code">
                                                    <template v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]">{{ check_empty(DDMMMYY(temp_atu_france[item.drug_id][sub_key].date_depot_ct)) }} </template>
                                                    <span v-else> - </span>
                                                </td>
                                               
                                                <td v-else-if="column.Status && column.code == 'aap_has_005'" :code="column.code">
                                                    <template v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]">{{ check_empty(DDMMMYY(temp_atu_france[item.drug_id][sub_key].date_avis_ct)) }} </template>
                                                    <span v-else> - </span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'aap_has_007'" :code="column.code">
                                                    <template v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]"> 
                                                        <template v-if="temp_atu_france[item.drug_id][sub_key].maladie_rare === 1 || temp_atu_france[item.drug_id][sub_key].maladie_grave === 1 || temp_atu_france[item.drug_id][sub_key].maladie_invalidante === 1">{{$t('yes')}}</template>
                                                        <template v-else-if="temp_atu_france[item.drug_id][sub_key].maladie_rare === null || temp_atu_france[item.drug_id][sub_key].maladie_grave === null || temp_atu_france[item.drug_id][sub_key].maladie_invalidante === null">-</template>
                                                        <template v-else>{{$t('no')}}</template>
                                                    </template>
                                                    <span v-else> - </span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'aap_has_008'" :code="column.code">
                                                    <template v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]"> {{ check_empty_with_locale(temp_atu_france[item.drug_id][sub_key].existence_traitements_appropries, 'evaluation_economic.yes_no_')}} </template>
                                                    <span v-else> - </span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'aap_has_010'" :code="column.code">
                                                    <template v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]"> {{ check_empty_with_locale(temp_atu_france[item.drug_id][sub_key].peut_pas_etre_differee, 'evaluation_economic.yes_no_')}} </template>
                                                    <span v-else> - </span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'aap_has_009'" :code="column.code">
                                                    <template v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]"> {{ check_empty_with_locale(temp_atu_france[item.drug_id][sub_key].check_efficacite_et_securite, 'evaluation_economic.yes_no_')}} </template>
                                                    <span v-else> - </span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'aap_has_011'" :code="column.code">
                                                    <template v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key]"> 
                                                        <template v-if="temp_atu_france[item.drug_id][sub_key].criteres_presumant_le_caractere_innovant_1 === 1 && temp_atu_france[item.drug_id][sub_key].criteres_presumant_le_caractere_innovant_2 === 1 && temp_atu_france[item.drug_id][sub_key].criteres_presumant_le_caractere_innovant_3 === 1"> {{$t('yes')}} </template>
                                                        <template v-else-if="temp_atu_france[item.drug_id][sub_key].criteres_presumant_le_caractere_innovant_1 === null || temp_atu_france[item.drug_id][sub_key].criteres_presumant_le_caractere_innovant_2 === null || temp_atu_france[item.drug_id][sub_key].criteres_presumant_le_caractere_innovant_3 === null">-</template>
                                                        <template v-else> {{$t('no')}} </template>
                                                    </template>
                                                    <span v-else> - </span>
                                                </td>
                                                <template v-else-if="column.Status && column.code == 'aap_has_012'" :code="column.code">
                                                    <td :code="'aap_has_0121'">
                                                        <span v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key] && temp_atu_france[item.drug_id][sub_key].drug_centers" v-html="hta_process_status_2(temp_atu_france[item.drug_id][sub_key].drug_centers)"></span>
                                                        <span v-else> - </span>
                                                    </td>
                                                    <td :code="'aap_has_0122'">
                                                        <span v-if="temp_atu_france[item.drug_id] && temp_atu_france[item.drug_id][sub_key] && temp_atu_france[item.drug_id][sub_key].drug_centers" v-html="rembursement_status_2(temp_atu_france[item.drug_id][sub_key].drug_centers)"></span>
                                                        <span v-else> - </span>
                                                    </td>
                                                </template>
                                                 
                                            </template>
                                        </tr>
                                        </template>
                                    </tbody>

                                </table>
                            </div>
                            <!---- Area Table Result End----->
                             
                            
                        </div>
                    </div> 

                    <div v-else class="body-w100" >
                        <div class="box-detail">
                            <div class="wrapper-box-hta">
                                <h1 class="fa-pull-left mr-4 mt-1">{{$t('No Data')}}</h1>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>  
                    </div>

                    <TablePrix v-if="$route.query.tab == 'prix'" main_page="Transparence" />

                    <TableEssaisClinique v-if="$route.query.tab == 'essais_clinique'" main_page="Transparence" />
                    

                </div>
            </div>
        </div>
        <ModalHTAstatus :item="item_hta_status" type="Transparence"/>
        <ModalEssaisClinique :id="nct_id_popup" agency="advance_result" />
        <Loader :isLoad="isLoad" />
        <Footer />
    </div>
</template>

<script>

import Header from '@/components/Layout/Header.vue'; 
import Footer from '@/components/Layout/Footer.vue'; 
import IconTopTable from '@/components/elements/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue'; 
import ConfigColumnAdvance from '@/components/advanced_result/modal_config_column_advance.vue';
import ModalSaveKeyword from '@/components/elements/modal_save_keyword.vue';
import { currentDate, isAdmin, isUserFrench, column_status, IndicationJoInfo, smr_type, hide_empty_row, hta_process_status_2, 
        rembursement_status_2, rembursement_status_all_type, eventHandlerResize, icon_link, check_empty, DDMMMYY, array, 
        treatment_line, color_type_limitation, datediff, ema_id, modifyPage, check_empty_with_locale,  odd_even_border } from '@/utils';
import FilterTransparence from '@/components/advanced_result/filter_transparence.vue'  
import Readmore from '@/components/elements/readmore.vue';
import TablePrix from '@/components/advanced_result/table_prix.vue';
import TableEssaisClinique from '@/components/advanced_result/table_essais_clinique.vue';
import ModalHTAstatus from '@/components/result/modal_hta_status.vue';
import ModalEssaisClinique from '@/components/elements/modal_essais_clinique.vue';
import Loader from "@/components/Layout/Loader";
// Initialize exporting module.

export default {
    name: 'advanced_result_transparence',
    components: {        
        Header,
        Footer,   
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        ConfigColumnAdvance,
        Readmore,
        FilterTransparence,
        ModalSaveKeyword,
        TablePrix,
        ModalHTAstatus,        
        TableEssaisClinique,
        ModalEssaisClinique,
        Loader
    },
    data() {
        return {
            actionName: "result_transparence",            
            temp_smr : {},
            temp_ctgov : {},
            temp_atu_france : {},
            temp_atu_france_post_atu : {},
            isFilter : false,
            item_hta_status : {},
            page : "transparence",
            nct_id_popup : 0 //for essais clinique popup

        }
    },
    mounted() { 
        //search
        console.log(' load tab TC...........')        
        this.request_data({ mounted : true, order_by : 'decision_date_num', sort : 'desc' })                      
        //this.$store.dispatch(`advanced_result/result_transparence_chart`, {...this.$route.params, chart:true}) 
        //this.chart_asmr_obtained()  
        document.body.classList.add('body-bg-gray') 
        if(this.$route.query.zoom){
            this.eventHandlerResize()
        }
        
    },
    updated(){
        hide_empty_row(`#${this.actionName}`)
    },

    methods: {
        request_data(param = {}){
            let paramObj = {...this.$route.params}
            paramObj['base64Search'] = paramObj['base64Search'].replace('/\-/g', '+').replace('/_/g', '/') // eslint-disable-line
            if(!param['mount'] && this.sort_column && Object.keys(this.data).includes(this.sort_column['order_by'])){
                paramObj['order_by'] = this.sort_column['order_by']
            }
            else if(param['order_by']){
                paramObj['order_by'] = param['order_by']
            }
            else{
                paramObj['order_by'] = 'decision_date_num'
            }
            paramObj['sort'] = !param['mounted'] && this.sort_column && this.sort_column['sort'] ? this.sort_column['sort'] : param['sort']
            if(param.page){
                paramObj['page'] = param.page
            }
            if(this.select_export_obj.isSelect){
                paramObj['select_export'] = this.select_export_obj.listID
            }
            if(this.data_filter !== ""){
                paramObj['filter'] = this.data_filter
            }
            if(this.$route.query.keyword_id){
                paramObj['keyword_id'] = this.$route.query.keyword_id
            }
            if(param.export){
                delete param.export;
                this.$store.dispatch(`advanced_result/result_transparence_excel`, [paramObj, currentDate()+"_advanced_transparence" ] ) 
            }else{
                this.$store.dispatch(`advanced_result/result_transparence`, paramObj ) 
            }
             
            if(!param.mounted){
                this.selectTab('expert')
            }
            
        },   
        selectTab(tab){
            let query = Object.assign({}, this.$route.query);
            if(query.tab !== tab){
                query.tab = tab            
                console.log(this.$route.query)
                this.$router.push({ query });  
            }                       
        },
        selectExport(){
            this.$store.dispatch("advanced_result/select_export", { key : "isSelect" , value : true})                 
            this.request_data() 
        },
        clearExport(){
            this.$store.dispatch("advanced_result/select_export", "clear")                      
            this.request_data()
        },
        hta_status_modal(item){
            this.item_hta_status = item
        },
        join_text(param, key){
            let result = []
            if(param){ 
                param.forEach((val) => {
                    if(val && val[key] && val[key] !== "null"){
                        result.push(val[key])
                    }                    
                })
            }
            return result.join(", ")
        },
        display_design_outcome(param, outcome_type){
            let measure = [];
            param.forEach((item) => {
                if(item.outcome_type === outcome_type){
                    measure.push(item.measure);
                }
            })  
            return measure.join(", ")
        },
        column_status,
        IndicationJoInfo,
        smr_type,
        hta_process_status_2,
        rembursement_status_2,
        rembursement_status_all_type,
        eventHandlerResize,
        modifyPage,
        icon_link,
        check_empty,
        DDMMMYY,
        array,
        treatment_line,
        color_type_limitation,
        datediff,
        ema_id,
        check_empty_with_locale,
        odd_even_border,
        handleClickBoxSearch(){
            this.$store.dispatch("header/setOpenBoxSearch", !this.openBoxSearch)
        }, 
        handleClickShowFilter(){
            this.$store.dispatch("advanced_result/set_show_filter", true)
        }, 
        json_fields : function(param, key){ 
            let txt = ''
            if(param){   
                for(const val of param){
                    if(key == "ISP_libelle"){
                        txt += val[key] ?  "<p>"+ this.$t("isp."+val[key])+"</p>" : ""
                    }else{
                        txt += val[key] ? `<p>${val[key]}</p>` : ""
                    }                    
                }       
            }
            return txt
        },
        objet_audition(param){
            let txt = ''
            if(param){   
                param.forEach((val) => {
                     txt += (val ?  "<p>"+ this.$t("transparence.phase_contradictoire."+val)+"</p>" : "")
                })  
            }
            txt = this.check_empty(txt)
            return txt
        },                
        array_ctgov_study : function(param){
            const re_data = [];   
            for(const item of param){  
                if(item['ctgov_study']){
                    for(const subItem of item['ctgov_study']){
                        re_data.push(subItem)                    
                    }
                }
            }
            return re_data;
        },
        array_transparence_smr : function(param){
            const re_data = [];   
            for(const item of param){  

                let tempData = {...item}; 
                tempData.ctgov_study = [];
                for(const subItem of re_data){
                    if(item['smr_id'] == subItem['smr_id']){ 
                        tempData.smr_id = null; tempData.smr_title_en = null; tempData.smr_title = null; tempData.smr_type = null;
                        tempData.smr_text_en = null; tempData.smr_text = null; 
                        tempData.isp_json = null; tempData.nombre_de_patient_json = null; tempData.besoin_medical_json = null; tempData.nct_numbers=null;
                    }
                    if(item['asmr_id'] == subItem['asmr_id']){
                        tempData.asmr_id = null; tempData.asmr_title_en = null; tempData.asmr_title = null; tempData.asmr_obtenue = null
                        tempData.asmr_demande = null; tempData.asmr_text_en = null; tempData.asmr_text = null
                    }
                }
                
                if(tempData.smr_id || tempData.asmr_id){    
                    re_data.push(tempData)
                }
            }
            return re_data;
        },     
        array_atu_france : function(param){
            const re_data = [];   
            for(const item of param){  
                re_data.push(item)                    
            }
            return re_data;
        },  
        array_atu_france_post_atu : function(param){
            const re_data = [];   
            for(const item of param){  
                if(item['post_atu']){
                    for(const subItem of item['post_atu']){
                        re_data.push(subItem)                    
                    }
                }
            }
            return re_data;
        }, 
        max_row(param){
            const temp = []
            temp.push(1)             
            if(param.transparence && param.transparence.transparence_smr){
                temp.push(this.array_transparence_smr(param.transparence.transparence_smr).length) 
                temp.push(this.array_ctgov_study(param.transparence.transparence_smr).length) 
            }

            if(param.transparence && param.transparence.atu_france){
                temp.push(this.array_atu_france(param.transparence.atu_france).length)
                temp.push(this.array_atu_france_post_atu(param.transparence.atu_france).length)
            }
            
            if(this.indication_jo_info[param.drug_id]){
                temp.push(this.indication_jo_info[param.drug_id].length)
            }
            
            return Math.max(...temp)
        },
        chart_asmr_obtained(){
            let options = {
                chart: {
                    type: 'column',
                    renderTo: 'chart_asmr_obtained', 
                    backgroundColor: null,                   
                },
                exporting: {enabled: false},
                credits: {
                    enabled: false,
                },
                title: {
                    text: ''
                },  
                legend: {
                    enabled: false
                },
                xAxis: {
                    categories: [], //put data
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    gridLineWidth: 1, 
                    title: {
                        enabled: false
                    },
                    labels:
                        {
                            enabled: false
                        },
                        stackLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'normal',
                            fontSize: '13px',
                            color: 'gray'
                        }
                    }
                },
                tooltip:{
                headerFormat: '',
                    pointFormat : '<strong>{point.category} : {point.y}</strong>', 
                },                
                plotOptions: {
                series: {
                        dataLabels: {
                            enabled: false
                        },
                        pointWidth: 45,
                    },
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: false
                        }
                    }
                },
                series: [{
                    name: '',
                    data: []
                }]
            };
          
            if(this.data_chart.length){
                const data_chart = this.data_chart
                let categories = data_chart.map((item) => {
                    return item.name
                })            
                options.series[0].data = data_chart;     
                options.xAxis.categories = categories; 
            }
        }, 
         
    },
    computed: {
        isAdmin,
        isUserFrench,
        user(){
            return this.$store.getters['auth/user']
        },
        data(){
            return this.$store.getters['advanced_result/data'];
        },
        data_chart(){
            return this.$store.getters['advanced_result/data_chart'];
        },  
        orderColumn(){
            const columns = this.$store.getters['advanced_result/column']; 
            let tempColumns = []
            for (const item of columns) {
                tempColumns = [...tempColumns, ...item.column]
            } 
            return tempColumns               
        },  
        default_column(){
            const columns = this.$store.getters['advanced_result/default_column']; 
            let tempColumns = []
            for (const item of columns) {
                tempColumns = [...tempColumns, ...item.column]
            } 
            return tempColumns    
        },
        openBoxSearch () {  
            return this.$store.getters['header/openBoxSearch']
        },                 
        count() {
            return this.$store.getters['advanced_result/count']
        },                 
        count_filter() {
            return this.$store.getters['advanced_result/count_filter']
        },
        isLoad() {  
            if(this.$store.getters['advanced_result/load'] || this.$store.getters['advanced_result/load_chart'] || this.$store.getters['advanced_result/prix_load'] || this.$store.getters['essais_clinique/load'] ){
                return true
            }
            return false
        },
        select_export: {
            set(val){ this.$store.dispatch("advanced_result/select_export", {key: 'listID', value: val}) },
            get(){ 
                if(this.$store.getters['advanced_result/select_export'].checkAll){
                    return true
                }else{
                    return this.$store.getters['advanced_result/select_export'] ? this.$store.getters['advanced_result/select_export'].listID : null
                }
            }
        },
        select_export_obj(){
            return this.$store.getters['advanced_result/select_export']
        },
        data_filter(){
            return this.$store.getters['advanced_result/data_filter']
        }, 
        indication_jo_info(){
            let result = {}
            if(this.data.data && this.data.data.length > 0){
                this.data.data.forEach((val) => {
                    //console.log(val)
                    result[val.drug_id] = []
                    let check_jo_info = []                    
                    if(val.transparence && val.transparence.transparence_smr){                        
                        val.transparence.transparence_smr.forEach((smr) => {
                            let tempArr = []
                            if(val.transparence.indication_jo_info){
                                val.transparence.indication_jo_info.forEach((jo_info) => {
                                    if(jo_info.list_smr_id && jo_info.list_smr_id.includes(smr.smr_id)){
                                        check_jo_info.push(jo_info.id);
                                        tempArr.push(jo_info)
                                    }
                                })
                            }
                            result[val.drug_id].push(tempArr)
                        })
                    }
                    if(val.transparence && val.transparence.indication_jo_info){
                        val.transparence.indication_jo_info.forEach((jo_info) => {
                            if(!check_jo_info.includes(jo_info.id)){                                
                                result[val.drug_id].push([jo_info]);
                            }
                        })
                    }                   
                })
            }            
            return result
        },
        sort_column(){
            return this.$store.getters['advanced_result/sort_column']
        },
        type(){
            return this.$store.getters['advanced_result/type'] 
        },
        column(){
            return this.$store.getters['advanced_result/column']                       
        },
        reset_column(){
            return this.$store.getters['advanced_result/reset_column']                       
        },
    },
    watch: {        
        data_chart: function () {           
            this.chart_asmr_obtained()    
        },    
        data: function (newData) {
            let arr_temp_smr = {}
            let arr_temp_ctgov = {}
            let arr_temp_atu_france = {}
            let arr_temp_atu_france_post_atu = {}
            for(let item of newData.data){
                if(item.transparence.transparence_smr){
                    arr_temp_smr[item.drug_id] = this.array_transparence_smr(item.transparence.transparence_smr)
                    arr_temp_ctgov[item.drug_id] = this.array_ctgov_study(item.transparence.transparence_smr)
                }
                if(item.transparence.atu_france){
                    arr_temp_atu_france[item.drug_id] = this.array_atu_france(item.transparence.atu_france)
                    arr_temp_atu_france_post_atu[item.drug_id] = this.array_atu_france_post_atu(item.transparence.atu_france)
                }   
            }
            this.temp_ctgov = arr_temp_ctgov
            this.temp_smr = arr_temp_smr
            this.temp_atu_france = arr_temp_atu_france
            this.temp_atu_france_post_atu = arr_temp_atu_france_post_atu
            hide_empty_row(`#${this.actionName}`)
        }, 
    },
    created() {
        if(this.$route.query.zoom){
            window.addEventListener("resize", this.eventHandlerResize);
        }
    },
    unmounted () {
        document.body.classList.remove('body-bg-gray'); 
        this.$store.dispatch("advanced_result/clearState")
        this.$store.dispatch("essais_clinique/clearState")
        this.$store.dispatch("detail/clearState")
        if(this.$route.query.zoom){
            window.removeEventListener("resize", this.eventHandlerResize);
        }
    }
}
</script>

<style scoped>
.main-body{
    min-height: 654px;
} 
/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 60vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>