<template>
    <div>
        <div :class="'box-filter ' + (show_filter ? 'active' : '')">
            <div class="filter-inner apply" @click="handleSubmit">
                <img :src="require(`@/assets/dist/2019/images/icon/btn-ok${isUserFrench ? '-fr' : ''}.svg`)" alt="">
                <p>{{$t('Apply')}}</p>
            </div>
            <div class="filter-inner reset" @click="handleReset">
                <img src="@/assets/dist/2019/images/icon/checklist-reload.svg" alt="">
                <p>{{$t('Reset')}}</p>
            </div>
            <div class="filter-inner cancel" @click="handleCancel">
                <img :src="require(`@/assets/dist/2019/images/icon/ic-close${isUserFrench ? '-fr' : ''}.svg`)" alt="">
                <p>{{$t('Cancel')}}</p>
            </div>
        </div>
        <div :class="'sub-menu-filter menu-filter advance ' + (show_filter ? 'active' : '')" style="z-index: 99;">
            <ul> 
                <li v-for="(item,key) in filter" :key="key" >
                    <a v-if="item.title">{{item.title}} </a>
                    <ul class="list-checkbox w100">
                        <li v-for="(item2,key2) in item.options" :key="key+'-'+key2" :class="item.style">
                            <div class="box-check box-check-text box-check-xs w-100">
                                <label>
                                    <input type="checkbox" :value="item2.value" :name="item2.name" v-model="data_filter[item2.name]">
                                    <span class="check-recherche"></span>
                                    <template v-if="item.color">
                                        <div class="txt-check text-gray" :style="'color:' + item.color" >
                                            {{item2.display}}
                                        </div>
                                    </template>
                                    <div v-else class="txt-check text-gray" >
                                        {{item2.display}}
                                    </div>
                                </label>
                            </div>
                        </li> 
                    </ul>
                </li>
            </ul>
        
        </div>
    </div>
</template>

<script>
import { Base64 } from 'js-base64'
import { isUserFrench } from '@/utils';
export default {
    
    name: "FilterTransparence",
    data() {
        return { 
            temp_filter : false,
            data_filter : {
                type_demande : [],
                raison_reevaluation : [],
                motif : [],
                prise_en_charge : [],
                smr : [],
                asmr_obtenue : [],
                classification : [],
                type_amm : [],
                atu_date_start : [], 
                orphelin : [],
                treatment_line : [],
                show_generic : [],
                hide_addition_range : []
            },
            filter : [
                {
                    "title" : "Type de demande",
                    "options" : [
                        {name : "type_demande", value:"INS", display:"Inscription"},
                        {name : "type_demande", value:"REINS", display:"Ré-inscription"},
                        {name : "type_demande", value:"EIT", display:"Extension d'indication"},
                        {name : "raison_reevaluation", value:"Request of authorities", display:"Ré-évaluation à la demande des autorités"},
                        {name : "raison_reevaluation", value:"Request of sponsor", display:"Ré-évaluation à la demande la firme"},
                        {name : "type_demande", value:"REEVAL", display:"Ré-évaluation"},
                        {name : "type_demande", value:"RADIATION", display:"Radiation"},
                        {name : "type_demande", value:"Modification de l’indication", display:"Modification indication / RCP"},
                        {name : "type_demande", value:"Remboursement non demandé", display:"Remboursement non demandé"}],     
                    style : "p-0 w-100",               
                },
                {
                    "title" : "Motif",
                    "options" : [
                        {name : "motif", value:"SS", display:"SS"},
                        {name : "motif", value:"SSCOLL", display:"SSCOLL"},
                        {name : "motif", value:"COLL", display:"COLL"}],
                    style : "p-0 w-100",  
                },
                {
                    "title" : "Prise en charge",
                    "options" : [
                        {name : "prise_en_charge", value:"Prise en charge", display:"Prise en charge"},
                        {name : "prise_en_charge", value:"Non pris en charge", display:"Non pris en charge"},
                        {name : "prise_en_charge", value:"En cours", display:"En cours"},
                        {name : "prise_en_charge", value:"Prise en charge à titre dérogatoire", display:"Modification de l'indication remboursable"},
                        {name : "prise_en_charge", value:"Radiation", display:"Radiation"}],
                    style : "p-0 w-100", 
                },
                {
                    "title" : "SMR",
                    "options" : [
                        {name : "smr", value:"important", display:"Important"},
                        {name : "smr", value:"modéré", display:"Modéré"},
                        {name : "smr", value:"faible", display:"Faible"},
                        {name : "smr", value:"insuffisant", display:"Insuffisant"},
                        {name : "smr", value:"non évaluable", display:"Non évaluable"}],
                    style : "two-column",
                },
                {
                    "title" :  this.$t('SMR conditionnel'),
                    "options" : [
                        {name : "conditionnel", value:"1", display:this.$t('Yes')},
                        ],
                    style : "p-0 w-100",
                },
                {
                    "title" : "ASMR obtenue",
                    "options" : [
                        {name : "asmr_obtenue", value:"I", display:"I"},
                        {name : "asmr_obtenue", value:"II", display:"II"},
                        {name : "asmr_obtenue", value:"III", display:"III"},
                        {name : "asmr_obtenue", value:"IV", display:"IV"},
                        {name : "asmr_obtenue", value:"V", display:"V"}],
                    style : "two-column",
                },
                {
                    "title" : "Classification ",
                    "options" : [
                        { name:"classification", value:"Nouvelle entité", display:"Nouvelle entité"},
                        { name:"classification", value:"Nouveau produit", display:"Nouveau produit"},
                        { name:"classification", value:"Nouvelle indication", display:"Nouvelle indication"},
                        { name:"classification", value:"Nouvelle association", display:"Nouvelle association"},
                        { name:"classification", value:"Complement de gamme", display:"Complement de gamme"},
                        { name:"classification", value:"Modification de l'indication", display:"Modification de l'indication"},
                        { name:"classification", value:"Autre", display:"Autre"}],
                    style : "p-0 w-100",
                },
                {
                    "title" : "Type d'AMM",
                    "options" : [
                        {name : "type_amm", value:"AMM conditionnelle", display:"AMM conditionnelle"},
                        {name : "type_amm", value:"AMM sous circonstances exceptionnelles", display:"AMM sous circonstances exceptionnelles"},
                        {name : "type_amm", value:"Biosimilaire", display:"Biosimilaire"},
                        {name : "type_amm", value:"Hybride", display:"Hybride"},
                        {name : "type_amm", value:"Generic", display:"Générique"}],
                    style : "p-0 w-100",
                },
                {
                    "title" : "ATU",
                    "options" : [
                        {name : "atu_date_start", value: 1, display:"Date d’octroi ATU"},
                        {name : "type_demande", value:"INS POST-ATU", display:"Inscription post-ATU"}],
                    style : "p-0 w-100",
                },
                {
                    "title" : "Orphelin",
                    "options" : [
                        {name : "orphelin", value:"1", display: this.$t('Orphan drug')},
                        {name : "orphelin", value:"2", display: this.$t('Previously Orphan')}],  
                    style : "p-0 w-100",
                },
                {
                    "title" : this.$t("Treatment line (Oncology)"),
                    "options" : [
                        { name:"treatment_line", value:"First line", display:this.$t("First line")}, 
                        { name:"treatment_line", value:"Second line", display:this.$t("Second line")}, 
                        { name:"treatment_line", value:"Third line", display:this.$t("Third line")},
                        { name:"treatment_line", value:"Rescue line", display:this.$t("Rescue line")},
                        { name:"treatment_line", value:"Maintenance", display:this.$t("Maintenance")},
                        { name:"treatment_line", value:"adjuvant", display:this.$t("adjuvant")},
                        { name:"treatment_line", value:"neo-adjuvant", display:this.$t("neo-adjuvant")}, 
                        { name:"treatment_line", value:"n/a", display:this.$t("n/a")}],
                    "style" : "two-column",
                },
                // {
                //     "title" : "",
                //     "options" : [
                //         {name : "show_generic", value:1, display:"Afficher les génériques ou Similaires"},
                //         {name : "hide_addition_range", value:1, display:"Ne pas afficher les compléments de gamme"}],
                //     style : "p-0 w-100",
                //     color : "#fa6c33"
                // },

            ],

        }
    }, 
    methods: {
        handleReset(){
            for (const key in this.data_filter) {
                this.data_filter[key] = []
            }            
            this.handleSubmit(false)
        },
        handleSubmit(isFilter = true){ 
             
            let strFilter = ""
            for (const [key, value] of Object.entries(this.data_filter)) {
                if(value.length > 0){
                    strFilter += `${key}=${value.join('#')}|` 
                }                
            }
            this.$store.dispatch("advanced_result/set_data_filter", Base64.encode(strFilter) )
            this.handleCancel()

            this.$emit('onRequest') 
            this.$emit('isFilter', isFilter) 
        }, 
        handleCancel(){
            this.$store.dispatch("advanced_result/set_show_filter", false)
        }, 
    },
    computed: {
        isUserFrench,
        show_filter () {  
            return this.$store.getters['advanced_result/show_filter']
        },  
    }, 
}
</script>

<style>

</style>